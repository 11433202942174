import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { Box, Typography, Tab, Tabs, List, ListItem } from '@mui/material'
import { sum } from 'lodash'
import { getDurationMilliFormatted } from 'utils/duration'
import { FULL_TIME_FORMAT, MM_SS_TIME_FORMAT, ITALIAN_TIME_FORMAT, ITALIAN_MM_SS_FORMAT } from 'utils/constants'
import config from 'utils/config'
import { CourseView } from 'types/CourseView'
import { translations } from 'locales/i18n'
import NoteEditorForm from './NoteEditorForm'
import textLessonBg from 'images/lesson-text-bg.svg'
import ReactQueryKeys from 'types/ReactQueryKeys'

const style = {
  indicator: {
    '& .MuiTabs-scroller.MuiTabs-fixed .MuiTabs-indicator': {
      backgroundColor: '#F17576',
    },
  },
  tab: {
    alignItems: 'start',
    p: 0,
    minWidth: 'unset',
    mr: '22px',
  },
  scrollable: {
    maxHeight: {
      xs: '400px',
      lg: '280px',
    },
    width: {
      xs: '99%',
      md: '100%',
    },
    overflow: 'auto',
    pr: {
      xs: '20px',
      md: 0,
    },
    pb: 2,
  },
} as const

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>{children}</Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

type LessonTabsProps = {
  currentLessonId: string
  course: CourseView
  showNotesTab: boolean
  noteTextUpdatedAt?: number
  noteText?: string
  onNoteTextReset: () => void
  onNoteTextSubmit: (noteText: string) => void
}

const LessonTabs: FC<LessonTabsProps> = ({
                                           currentLessonId,
                                           course,
                                           showNotesTab,
                                           noteTextUpdatedAt,
                                           noteText,
                                           onNoteTextReset,
                                           onNoteTextSubmit,
                                         }) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const [currentTab, setCurrentTab] = React.useState(showNotesTab ? 1 : 0)
  const { name, lessons } = course
  const isVideoCourse = course.lessons.some(lesson => !!lesson.videoUri)
  const summaryDuration = sum(lessons.map(({ videoLength }) => videoLength))

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue)
    newValue === 1 && queryClient.invalidateQueries([ReactQueryKeys.LESSON])

  }

  return (
    <>
      <Box sx={{
        p: { xs: '15px 15px 0', md: '20px 20px 0' },
        borderTopLeftRadius: '3px',
        borderTopRightRadius: '3px',
        background: theme => theme.palette.background.paper,
      }}
      >
        <Typography sx={{
          fontSize: { xs: '14px', md: '18px' },
          lineHeight: { xs: '17px', md: '22px' },
          fontWeight: '800',
          mb: { xs: '10px', md: '15px' },
        }}>{name}</Typography>
        <Tabs value={currentTab} onChange={handleChange} aria-label="" sx={{ ...style.indicator }}>
          {showNotesTab && <Tab
              className="u-text-transform-none u-color-white"
              label={t(translations.lessonPage.myNotes)}
              {...a11yProps(0)} sx={{ ...style.tab }}
          />}
          <Tab
            className="u-text-transform-none u-color-white"
            label={t(translations.lessonPage.allLessons)}
            {...a11yProps(1)}
            sx={{ ...style.tab }}
          />
        </Tabs>
      </Box>
      {showNotesTab && <TabPanel value={currentTab} index={0}>
          <Box sx={{ width: '100%' }}>
              <NoteEditorForm noteTextUpdatedAt={noteTextUpdatedAt}
                              noteText={noteText}
                              onSubmit={onNoteTextSubmit}
                              height={330}/>
          </Box>
          <Box sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}>
              <Box
                  sx={{
                    p: { xs: '8px 10px', md: '8px 20px' },
                    border: '1px solid #FFFFFF',
                    borderRadius: '3px',
                    cursor: 'pointer',
                    mr: { md: '20px' },
                  }}
                  onClick={onNoteTextReset}
              >
                  <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                  >
              <span className="u-fs-16 u-lh-19"
                    style={{ letterSpacing: '0.5px' }}>{t(translations.lessonPage.note.resetButton)}</span>
                  </Box>
              </Box>
          </Box>
      </TabPanel>}
      <TabPanel value={currentTab} index={showNotesTab ? 1 : 0}>
        <Box>
          {isVideoCourse && <>
            <Typography className="u-pb-5 u-opacity-50" sx={{
              fontSize: { xs: '10px', md: '14px' },
              lineHeight: { xs: '16px', md: '20px' },
              width: { lg: '80%' },
            }}>
              {t(translations.lessonPage.courseContains)}
            </Typography>
            <Typography className="u-pb-5 u-opacity-50"
                        sx={{ fontSize: { xs: '12px', md: '14px' }, lineHeight: { xs: '14px', md: '17px' } }}>
              {getDurationMilliFormatted(summaryDuration * 1000, (summaryDuration > 3600 ? ITALIAN_TIME_FORMAT : ITALIAN_MM_SS_FORMAT))}
            </Typography>
          </>}
          <Box sx={{ ...style.scrollable }} className="u-pt-10 u-pb-10">
            <List sx={{ width: '100%' }}>
              {lessons
                .filter(({ id }) => id !== currentLessonId)
                .map(({ courseId, id: lessonId, name, lecturer, videoLength, previewImageUri, videoUri }) => {
                  const isVideoLesson = !!videoUri
                  return (
                    <Link to={`/courses/${courseId}/lessons/${lessonId}`} key={lessonId}>
                      <ListItem
                        alignItems="flex-start"
                        sx={{ p: '0 0 10px', cursor: 'pointer' }}
                      >
                        <Box
                          className="u-position-relative"
                          sx={{
                            width: '40%',
                            background: !previewImageUri ? 'linear-gradient(180deg, #37679D 0%, #E86393 97.49%)' : '',
                          }}
                        >
                          <img
                            src={previewImageUri ? `${config.cdnBaseApiURI}${previewImageUri}` : textLessonBg}
                            alt="Video lesson"
                            className="u-h-100 u-w-100"
                          />
                          {isVideoLesson && <Box sx={{
                            bgcolor: theme => theme.palette.background.paper,
                            borderRadius: '23px',
                            position: 'absolute',
                            bottom: '10px',
                            right: '3%',
                            p: '5px 10px',
                          }}>
                              <Typography sx={{
                                fontSize: { xs: '12px', md: '14px' },
                                lineHeight: { xs: '17px', md: '20px' },
                                fontWeight: '400',
                              }}>{getDurationMilliFormatted(videoLength * 1000, (videoLength > 3600 ? FULL_TIME_FORMAT : MM_SS_TIME_FORMAT))}</Typography>
                          </Box>}
                        </Box>
                        <Box sx={{ pl: 2, pr: 2, pb: 1, width: '60%' }}>
                          <Typography sx={{
                            maxHeight: '66px',
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 3,
                            overflow: 'hidden !important',
                            fontSize: { xs: '12px', md: '18px' },
                            lineHeight: { xs: '14px', md: '22px' },
                          }}>{name}</Typography>
                          <Typography className="u-opacity-50 u-text-overflow-ellipsis" sx={{
                            fontSize: { xs: '10px', md: '14px' },
                            lineHeight: { xs: '16px', md: '20px' },
                          }}>{lecturer}</Typography>
                        </Box>
                      </ListItem>
                    </Link>
                  )
                })}
            </List>
          </Box>
        </Box>
      </TabPanel>
    </>
  )
}

export default LessonTabs
