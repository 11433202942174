import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { alpha, Box, Grid } from '@mui/material'

import { QuizType } from 'types/QuizType'
import { translations } from 'locales/i18n'
import { QuizAttemptQuestion } from 'types/QuizAttemptQuestion'
import { simpleQuizTypes } from './constants'
import { Dictionary } from 'lodash'
import { QUESTION_ANCHOR_PREFIX } from 'utils/constants'


type QuizAttemptSectionsProps = {
  quizType: QuizType
  activeSectionId: string
  isDividedBySections: boolean
  sections: QuizAttemptSections[]
  quizQuestions: QuizAttemptQuestion[]
  currentQuestion: QuizAttemptQuestion | undefined
  getQuestionNavigation?: (number: number) => string
  answeredQuestionsGroupedBySection: Dictionary<[QuizAttemptQuestion, ...QuizAttemptQuestion[]]>
  questionsGroupedBySubject: Dictionary<[QuizAttemptQuestion, ...QuizAttemptQuestion[]]>
  answeredQuestionsGroupedBySubject: Dictionary<[QuizAttemptQuestion, ...QuizAttemptQuestion[]]>
}

const isActiveSection = (currentQuestion: QuizAttemptQuestion, section: QuizAttemptSections) =>
  section.subjects.includes(currentQuestion.question.subject.id)

const QuizAttemptSections: FC<QuizAttemptSectionsProps> = ({
  quizType,
  activeSectionId,
  sections,
  isDividedBySections,
  quizQuestions,
  currentQuestion,
  getQuestionNavigation,
  answeredQuestionsGroupedBySection,
}) => {
  const { t } = useTranslation();
  const qustionToSection = new Map();
  !simpleQuizTypes.includes(quizType) &&
    quizQuestions.forEach((question) => {
      const section = sections.find((section) =>
        section.subjects.includes(question.question.subject.id)
      );

      const questList =
        qustionToSection.get(section?.id) === undefined
          ? []
          : qustionToSection.get(section?.id);
      questList.push(question);
      qustionToSection.set(section?.id, questList);
    });
    
//TODO Add sort for sections in old version of quizzes
  // const sectionsSort = (sections: QuizAttemptSections[]) => {
  //   return sections.sort((a, b) => {
  //     const aFirstQuestion = qustionToSection.get(a.id)?.[0]?.number || 0;
  //     const bFirstQuestion = qustionToSection.get(b.id)?.[0]?.number || 0;
  //     return aFirstQuestion - bFirstQuestion;
  //   });
  // };

  const activeSectionIdx =
    sections && sections?.findIndex(({ id }) => id === activeSectionId);

  return !simpleQuizTypes.includes(quizType) ? (
    <Box>
      <Box
        sx={{
          mt: { xs: "13px", lg: "45px" },
          mb: { xs: "10px", lg: "18px" },
          letterSpacing: "2px",
          fontSize: { xs: "10px", lg: "12px" },
          lineHeight: "14px",
          opacity: "60%",
        }}
      >
        {t(translations.quizAttemptPage.sections)}
      </Box>

      {isDividedBySections
        ? sections.map((section: QuizAttemptSections, index) => (
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              sx={{
                height: { xs: "47px", lg: "55px" },
                marginLeft: { lg: "-17px" },
                padding: "14px 17px",
                borderRadius: "3px",
                width: "100%",
                background: (theme) =>
                  activeSectionId === section.id
                    ? theme.palette.background.paper
                    : "unset",
                color: (theme) =>
                  index >= activeSectionIdx
                    ? theme.palette.text.primary
                    : theme.palette.text.secondary,
              }}
            >
              <Grid
                item
                sx={{
                  fontSize: { xs: "12px", lg: "16px" },
                }}
              >
                {section.name}
              </Grid>
              <Grid
                item
                sx={{
                  fontSize: { xs: "12px", lg: "18px" },
                  fontWeight: "600",
                }}
              >
                {answeredQuestionsGroupedBySection[section.name] && (
                  <Box>
                    <span>
                      {`${
                        answeredQuestionsGroupedBySection[section.name].length
                      }/`}
                    </span>
                    <span>{qustionToSection.get(section.id).length}</span>
                  </Box>
                )}

                <Box>
                  {!answeredQuestionsGroupedBySection[section.name] &&
                    qustionToSection.get(section.id) &&
                    qustionToSection.get(section.id).length}
                </Box>
              </Grid>
            </Grid>
          ))
        : sections.map((section) => (
            <>
              {qustionToSection.get(section.id) && (
                <a
                  href={`#${QUESTION_ANCHOR_PREFIX}${
                    qustionToSection.get(section.id)?.[0]?.number
                  }`}
                  style={{ color: "white", textDecoration: "none" }}
                  id={
                    getQuestionNavigation &&
                    getQuestionNavigation(
                      qustionToSection.get(section.id)?.[0]?.number
                    )
                  }
                  key={`questionNavigation${
                    qustionToSection.get(section.id)?.[0]?.number
                  }`}
                >
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                      height: { xs: "47px", lg: "55px" },
                      marginLeft: { lg: "-17px" },
                      padding: "14px 17px",
                      borderRadius: "3px",
                      width: "100%",
                      background: (theme) =>
                        currentQuestion &&
                        isActiveSection(currentQuestion, section)
                          ? theme.palette.background.paper
                          : "unset",
                      "&:hover": {
                        backgroundColor: (theme) =>
                          alpha(theme.palette.background.paper, 0.5),
                      },
                    }}
                  >
                    <Grid
                      item
                      sx={{
                        fontSize: { xs: "12px", lg: "16px" },
                      }}
                    >
                      {section.name}
                    </Grid>
                    <Grid
                      item
                      sx={{
                        fontSize: { xs: "12px", lg: "18px" },
                        fontWeight: "600",
                      }}
                    >
                      {answeredQuestionsGroupedBySection[section.name] && (
                        <Box>
                          <span className="">
                            {`${
                              answeredQuestionsGroupedBySection[section.name]
                                .length
                            }/`}
                          </span>
                          <span className="u-color-pale-sky">
                            {qustionToSection.get(section.id).length}
                          </span>
                        </Box>
                      )}

                      <Box>
                        {!answeredQuestionsGroupedBySection[section.name] &&
                          qustionToSection.get(section.id) &&
                          qustionToSection.get(section.id).length}
                      </Box>
                    </Grid>
                  </Grid>
                </a>
              )}
            </>
          ))}
    </Box>
  ) : (
    <></>
  );
};

        export default QuizAttemptSections
